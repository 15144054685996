<script setup>
    import { onMounted } from 'vue';
    import DevMenu from './components/DevMenu.vue';
    import { StateUser } from './modules/StateImges';
    import { useRouter } from 'vue-router';
    import { getUser } from './modules/api';
import { Cookies } from './modules/cookie';

    let router = useRouter();


    onMounted( async () => {
        console.log("APP RELOAD")


        // MAKE SURE USER 'LOGIN' IS VALIDATED ON *EVERY* FULL RELOAD
        let _isLoggedIn = await StateUser.isLoggedIn();
        if(!_isLoggedIn){
            router.push("/");
            return;
        }

        // If the user is logged in we fetch and store the user info in state
        let _userUuid = Cookies.get("useruuid");
        let user = await getUser(_userUuid);
        StateUser.setUser(user)

    })  

</script>




<template>

    <DevMenu />

    <RouterView />


    <!-- <button @click="click">INSERT</button> -->

    <!-- <HomeMenu msg="IMGES"/> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

    <!-- <h1>Hello App!</h1>
  <p>
    <strong>Current route path:</strong> {{ $route.fullPath }}
  </p>
  <nav>
    <RouterLink to="/">Go to Home</RouterLink>
    <RouterLink to="/about">Go to About</RouterLink>
  </nav>
  <main>
    <RouterView />
  </main> -->

</template>



<script>




// import HelloWorld from './components/HelloWorld.vue'
// import HomeMenu from './components/home-menu.vue';


// window.addEventListener("popstate", @pop);





// let insertBtn = document.getElementById("_insert");
// insertBtn.addEventListener('click', () => {
//     console.log("CLIC")
// })


// function click(){
//     console.log("CLICKCLICL")
// } 

export default {
  name: 'App',
  components: {
    // HelloWorld,
    // HomeMenu
  },
  methods: { 
    pop(){
        // this.$router.back()
    }
  }
}
</script>




<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
  /* margin: 10px; */
  height: 98%;
  width: 96%;
  max-width: 700px;
  min-width: 320px;
  box-shadow: 0 0 0 5px rgb(27, 2, 189);
  overflow: hidden;
  background-color: rgb(91, 91, 91);
}



</style>
