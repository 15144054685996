// Add this script to your Vue component or main.js
export const autoFillInputs = (inputSelectors, values) => {
  // Wait for DOM to be ready
  document.addEventListener('DOMContentLoaded', () => {
    // Wait a bit for Vue to mount
    setTimeout(() => {
      inputSelectors.forEach((selector, index) => {
        const input = document.querySelector(selector);
        if (input) {
          // Set the value
          input.value = values[index];
          
          // Trigger input event to ensure Vue's reactivity picks it up
          input.dispatchEvent(new Event('input', { bubbles: true }));
          
          // If using v-model, we need to trigger change event too
          input.dispatchEvent(new Event('change', { bubbles: true }));
        }
      });
    }, 100);
  });
};

// Usage example:
export const testData = {
  selectors: ['#username', '#password', '#confirmPassword'],
  values: [`user-${Math.floor(Math.random()*1000)}`, 'p', 'p']
};


// autoFillInputs(testData.selectors, testData.values);