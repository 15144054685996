import { createApp } from 'vue'
import App from './App.vue'



// TESTING ROUTER 
import HelloWorld from './components/HelloWorld.vue'
import HomeMenu from './components/HomeMenu.vue';
import CreateUser from './components/CreateUser.vue';
import UserLogin from './components/UserLogin.vue';
import DashboardUser from './components/DashboardUser.vue';
import DeckList from './components/DeckList.vue';
import DeckOverview from './components/DeckOverview.vue';
import RoundPlay from './components/RoundPlay.vue';

import { createWebHistory, createRouter } from 'vue-router'
const routes = [
    { path: '/', component: HomeMenu },
    { path: '/login', component: UserLogin },
    { path: '/create-user', component: CreateUser },
    { path: '/dashboard-user', component: DashboardUser },
    { path: '/deck-list', component: DeckList },
    { path: '/deck-overview', component: DeckOverview },
    { path: '/round-play', component: RoundPlay },

    { path: '/:pathMatch(.*)*', redirect: '/' },

    { path: '/about', component: HelloWorld },
]



const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  console.log(`Navigating to ${to.fullPath} from ${from.fullPath}`);
  next();
});

createApp(App)
    .use(router)
    .mount('#app')



// Dev tests - 2024-11-12

// document.body.addEventListener("touchmove", (_event) => {
//     console.log("_event =", _event);
//     _event.preventDefault();
//     _event.stopImmediatePropagation();
// })
// document.body.addEventListener("touchstart", (_event) => {
//     console.log("_event =", _event);
//     _event.preventDefault();
//     _event.stopImmediatePropagation();
// })



// CRITICAL FOR IHPONE HEIGHT
function setBodyHeight() {
  const viewportHeight = window.innerHeight;
  document.body.style.height = `${viewportHeight}px`;
}
window.x = 1;
// var x = 1;
// console.log("x=", x)
// Call it on load and resize
window.addEventListener('load', setBodyHeight);
window.addEventListener('resize', setBodyHeight);