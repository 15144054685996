<script setup>
import { ref } from 'vue';
import { onMounted } from 'vue';

import { baseApiUrl } from '@/modules/api';
import DeckListItem from './DeckListItem.vue';

const decks = ref([]);

// Fetch cards on mount
const fetchDecks = async () => {
    console.log("FETCH")
    try {
    const response = await fetch(baseApiUrl + '/decks');
    if (response.ok) {
        decks.value = await response.json();
    } else {
        console.error('Failed to fetch cards');
    }
    } catch (error) {
    console.error('Error:', error);
    }
};

// return { cards };

onMounted(() => {
    fetchDecks()
    console.log("Mounted Deck-List")
})
</script>



<template>

    <LoadingScreen /> 

    <div class="deck-list-container">
        <h1>CHOOSE CATEGORY</h1>

        <div class="scrollable-list center">
            <DeckListItem v-for="deck in decks" :key="deck.uuid" :name="deck.name" :uuid="deck.uuid" />
        </div>

    </div>

</template>


<script>


// import DeckListItem from './DeckListItem.vue';
// import LoadingScreen from './LoadingScreen.vue';


export default {
  name: 'DeckList',
  props: {
    msg: String
  },
  methods: {
    click_deck() {
        console.log("DECK CLICKED ?")
        // window.location.replace(  
        //     "/login",
        // );
        // history.pushState({}, "", "/about");
        // this.$router.push('/about');
    }
  }
}
</script>


<style scoped>

.deck-list-container {
    height: 100%;
    width: 100%;
}

.scrollable-list {
    border: solid green 2px;
    height: 100%;
    justify-content: start;
}



</style>
