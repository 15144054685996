<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router';    



import LoadingScreen from './LoadingScreen.vue';

import { Cookies } from '@/modules/cookie';
// import { /* newUser, */ validateLoginCookie } from '@/modules/api';
import { StateUser } from '@/modules/StateImges';

const router = useRouter();

// import LoadingScreen from './LoadingScreen.vue';

console.log("Setup script")




let loginCookie = Cookies.get("login"); 
let useruuidCookie = Cookies.get("useruuid");

console.log("loginCookie ", loginCookie)
console.log("useruuidCookie ", useruuidCookie)

 
onMounted( async () => {

    let _isLoggedIn = await StateUser.isLoggedIn();
    console.log("_isLoggedIn =", _isLoggedIn)

    // let loginCookieOK = await validateLoginCookie();
    // console.log("loginCookieOK =", loginCookieOK)
    if(_isLoggedIn){
        router.push('/dashboard-user');
    }
     
    document.getElementById("loading-screen").style.backgroundColor = "rgba(0,0,0,0)";
    document.getElementById("loading-screen").style.pointerEvents = "none";
    // setTimeout(() => {document.getElementById("loading-screen").remove()}, 1000)
    // document.getElementById("loading-screen").remove();

})


</script>


<template>

    <LoadingScreen />

     <!-- <button @click="click_now">INSERT</button> -->
  <div class="home">
    <img id="home-graffiti" class="center" alt="Vue logo" src="../assets/imgess-graffiti.png">
    <!-- <button id="sign-in">SIGN IN</button> -->

    <div id="home-but-container">

        <router-link to="/login">
            <button id="sign-in" class="btn btn-home green">SIGN INs</button>
        </router-link>
        <!-- <a href="/login" target="_blank" rel="noopener" class="home-links">
            <button id="sign-in" class="btn btn-home green">SIGN IN</button>
        </a> -->
        <!-- <a href="/login" target="_blank" rel="noopener" class="home-links"> -->
        
        <button id="play-guest" class="btn btn-home transparent" @click="click_play_as_guest">PLAY AS GUEST</button>
        
        <!-- </a> -->
        <!-- <a href="/login" target="_blank" rel="noopener" class="home-links">
            <button id="create-account" class="btn btn-home transparent" @click="click_now">CREATE ACCOUNT</button>
        </a> --> 
        <!-- <router-link to="/about">About</router-link> -->
         <router-link to="/create-user">
            <button id="create-account" class="btn btn-home transparent">CREATE ACCOUNT</button>
         </router-link>
        
        <!-- <button id="create-account" class="btn btn-home transparent" @click="click_now">CREATE ACCOUNT</button> -->
    </div>

    <!-- <h1>{{ msg }}</h1> -->

    <!-- <p>Image guessing game!</p> -->
    
  </div>
</template>


<script>
// console.log("process.env.API_BASE_URL =", process.env.API_BASE_URL)

// eslint-disable-next-line
function click_now(event){
    console.log("ALSKFJASL DLAS")
    console.log(event)
}

export default {
  name: 'HomeMenu',
  props: {
    msg: String
  },
  methods: {
    click_now() {
        console.log("HELLO ?")
        // window.location.replace(  
        //     "/login",
        // );
        // history.pushState({}, "", "/about");
        this.$router.push('/about');
    },
    async click_play_as_guest() {

        // I WANT ONE CALL TO CREATE GUEST!

        let newGuestOk = await StateUser.newGuest();

        if(!newGuestOk){
            console.error("New guest creation failed")
        }
        // console.log("GUEST ?")
        // let newUsername = "guest-" + Math.floor(Math.random() * 10000);
        // let _newUser = newUser(newUsername, "", 1)
        // if(!_newUser){
        //     console.warn("New guest user failed. Staying on home page. ")
        //     return;
        // }

        // UPDATE STATE
        // let loginCookie = Cookies.get("login"); 
        // let useruuidCookie = Cookies.get("useruuid");
        // StateUser.state.userUuid = useruuidCookie;
        // StateUser.state.loginCookie = loginCookie;


        console.log("MOVING TO DASHBOARD FOR NEW GUEST!")
        // let _login = login(newUsername, "");

        this.$router.push('/dashboard-user');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
    height: inherit;
    width: 100%;
}

#home-graffiti {
    /* width: 120%; */
    height: 40%;
    position: absolute; 
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    /* left: calc(100cqw); */


    /* background-color: #2c3e50; */
    z-index: 0;
    /* display: none; */
} 

#home-but-container {
    position: relative;

    top: 30%;

    z-index: 1;

    height: 60%;
    width: 100%;
    

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
#home-but-container > * {
    width: 100%;
}

.home-links {
    width: 100%;
}

.btn-home {
    font-size: x-large;
    width: 80%;
    padding: 20px;
    border-radius: 15px;
}




</style>
