
// export const baseApiUrl = "http://localhost:8788/api";
export const baseApiUrl = window.location.origin + "/api";
// export const baseApiUrl = "https://imges.anderserik.com/api";
// console.log("API BASE TEST = ", window.location.origin + "/api")

// console.log("process.env.API_BASE_URL =", process.env.API_BASE_URL)



export async function roundInfo(){

   
}


export async function newRound(_deckuuid, _useruuid){
    
    try {
        // If registration successful, proceed with login
        const newRoundRequest = await fetch(baseApiUrl + '/rounds?' +`deckUuid=${_deckuuid}` + `&userUuid=${_useruuid}` ,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            deckUuid: _deckuuid,
            userUuid: _useruuid,
          })
        })

        if (!newRoundRequest.ok) {
            // alert("New Round Request Failed\n" + `${await newRoundRequest.json()[0]}`)
            throw new Error('New Round failed.')
        }

        console.log("New Round OK")
        

        return (await newRoundRequest.json())[0];


      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }

}


export async function getUser(uuid){
    try {
        // If registration successful, proceed with login
        const userRequest = await fetch(baseApiUrl + '/users/' + uuid, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!userRequest.ok) {
            alert("Unable to get user\n" + `${await userRequest.json()}`)
            throw new Error('Logout failed.')
        }

        console.log("Got User")
        

        return (await userRequest.json())[0];


      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }

}


export async function newUser(_userName, _password, _isGuest){

    try {
        // If registration successful, proceed with login
        const newUserRequest = await fetch(baseApiUrl + '/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: _userName,
            password: _password,
            isGuest: _isGuest,
          })
        })

        if (!newUserRequest.ok) {
            alert("New User Failed\n" + `${await newUserRequest.json()}`)
            throw new Error('Logout failed.')
        }

        console.log("New User OK")
        

        return (await newUserRequest.json())[0];


      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }
}

export async function login(_userName, _password){

    try {
        // If registration successful, proceed with login
        const newUserRequest = await fetch(baseApiUrl + '/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: _userName,
            password: _password,
          })
        })

        if (!newUserRequest.ok) {
            alert("Login Failed\n" + `${await newUserRequest.json()}`)
            throw new Error('Logout failed.')
        }

        console.log("New User OK")

        return true;


      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }
}



export async function logout(){

    try {
        // If registration successful, proceed with login
        const logoutRequest = await fetch(baseApiUrl + '/login', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (!logoutRequest.ok) {
            alert("Logout Failed\n" + `${await logoutRequest.body.json()}`)
            throw new Error('Logout failed.')
        }

        console.log("LOGOUT OK")

        return true;

        // If both registration and login are successful, redirect to dashboard
        // window.location.href = '/dashboard'
        // alert("Login succefull!!")
        // this.$router.push('/dashboard-user');

      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }
}


 
export async function validateLoginCookie(){

    try {
        // If registration successful, proceed with login
        const loginCookieValidation = await fetch(baseApiUrl + '/login', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (!loginCookieValidation.ok) {
            // alert("Login Failed\n" + `${await loginCookieValidation.json()}`)
            throw new Error('Login cookie validation failed. Please try logging in using user credentials.')
        }

        console.log("COOKIE OK")

        return true; 

        // If both registration and login are successful, redirect to dashboard
        // window.location.href = '/dashboard'
        // alert("Login succefull!!")
        // this.$router.push('/dashboard-user');

      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }
}




export async function wait(timeMs){
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve("Success!"); // Yay! Everything went well!
        }, timeMs);
        // reject();
    })
}