<script setup>

import { onMounted } from 'vue'
import { useRouter } from 'vue-router';

import { Cookies } from '@/modules/cookie';
import { logout, validateLoginCookie, wait } from '@/modules/api';

// import LoadingScreen from './LoadingScreen.vue';
console.log("Setup script")

const router = useRouter();


const aa = 1;


// 
let loginCookie = Cookies.get("login");
let useruuidCookie = Cookies.get("useruuid");

console.log("loginCookie ", loginCookie)
console.log("useruuidCookie ", useruuidCookie)


// let promse = await wait(1000);
// console.log("promise =", promse);



onMounted( async () => {
    let promse = await wait(100);
    console.log("promise =", promse);

    

    const cookieIsOk = await validateLoginCookie();
    console.log("cookieIsOk =", cookieIsOk)
    if(!cookieIsOk){
        console.warn("Cookie not good at dashboard. Returning to home/root.")
        router.push("/");
    }
    // console.log("ON_MOUNTED")
    // console.log("dashboard element =", document.querySelector(".dashboard"))
    console.log("aa =", aa)

    document.getElementById("loading-screen").style.backgroundColor = "rgba(0,0,0,0)";
    document.getElementById("loading-screen").style.pointerEvents = "none";
    // document.getElementById("loading-screen").remove();
})

 

// Doesn't work!
// mounted(() => {  
//     console.log("ON_MOUNTED")
//     console.log("dashboard element =", document.querySelector(".dashboard"))
// })

</script>




<template>

    <LoadingScreen />
    
     <!-- <button @click="click_now">INSERT</button> -->
  <div class="dashboard">

    <button id="logout-btn" class="btn orange" @click="logout_click">LOGOUT</button>

    <img id="home-graffiti" class="center" alt="Vue logo" src="../assets/imgess-graffiti.png">
    <!-- <button id="sign-in">SIGN IN</button> -->

    <div id="home-but-container">

        <h1>DASHBOARD</h1>


        

        <router-link to="/deck-list">
            <button id="create-account" class="btn play-btn transparent">PLAYs</button>
         </router-link>

         <p>Streak = #</p>

         <router-link to="/create-user">
            <button id="create-account" class="btn high-score-btn orange">HIGH SCORE</button>
         </router-link>


    </div>

    
  </div>
</template>


<script>

import LoadingScreen from './LoadingScreen.vue';
import { StateUser } from '@/modules/StateImges';
// import DeckList from './DeckList.vue';



export default {
  name: 'DashboardUser',
  props: {
    msg: String
  },
  // The setup-script above takes precedence 
  setup() {
    // DOESN"T PRINT if setup-script exists in component
    // console.log("Setup method") 
    
  },
  // This still runs despite a 'onMounted' method above
  mounted() {
    // console.log("MOUNTED 2") // 0
    // console.log("dashboard element =", document.querySelector(".dashboard"))
  },
  // No errors but was never able to run. Even when setup script and mounted-method was removed...
  onMounted() {
    // console.log("ON MOUNTED 2") // 0
    // console.log("dashboard element =", document.querySelector(".dashboard"))

  },
  methods: {
    click_now() {
        console.log("HELLO ?")
        // window.location.replace(
        //     "/login",
        // );
        // history.pushState({}, "", "/about");
        this.$router.push('/about');
    },

    async logout_click(){
        console.log("LOGOUT");

        // API
        let logoutOk = await logout();

        // 
        StateUser.clearState();
        // document.cookies.r
        Cookies.clear();

        console.log("logoutOk =", logoutOk);
        this.$router.push('/');
        
    }
  }
}
</script>





<style scoped>
.dashboard {
    height: inherit;
    width: 100%;
}

#logout-btn {
    position: absolute;
    right: 0px;
    top: 50px;
}

#home-graffiti {
    /* width: 120%; */
    height: 40%;
    position: absolute; 
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    /* left: calc(100cqw); */


    /* background-color: #2c3e50; */
    z-index: 0;
    /* display: none; */
} 

#home-but-container {
    position: relative;

    top: 40%;

    z-index: 1;

    height: 60%;
    width: 100%;
    

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
#home-but-container > *{
    width: 100%;
}

.home-links {
    width: 100%;
}

.btn-dash {
    font-size: x-large;
    width: 80%;
    padding: 20px;
    border-radius: 15px;
}

.play-btn {
    font-size: 6vh;
    color: black;
}
.high-score-btn {
    font-size: xx-large;
}



</style>
