
<template>
    <div id="loading-screen">
        LOADING
    </div>
</template>


<script>
export default {
    name: 'LoadingScreen'
}
</script>


<style scoped>

#loading-screen {
    position: absolute;
    z-index: 1000;
    background-color: rgb(0, 0, 0);
    box-shadow: 0 0 0 10px yellow; 
    /* background-color: none; */
    height: 100%;
    width: 100%;
    max-width: 700px;

    pointer-events: all;
    transition: background-color 0.5s;
}

</style>