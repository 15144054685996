

<script setup>
import { onMounted } from 'vue';

import { StateUser } from '@/modules/StateImges';

onMounted(() => {
    
})

</script>




<template>
    <div class="dev-menu">
        D <br> <br>

        <div class="dev-row">
            <div class="dev-key">username</div>
            <div class="dev-equals"> = </div>
            <div class="dev-value">{{ StateUser.state.username }}</div>
        </div>

        <div class="dev-row">
            <div class="dev-key">userUuid</div>
            <div class="dev-equals"> = </div>
            <div class="dev-value">{{ StateUser.state.userUuid }}</div>
        </div>

        <div class="dev-row">
            <div class="dev-key">is guest?</div>
            <div class="dev-equals"> = </div>
            <div class="dev-value">{{ StateUser.state.isGuest }}</div>
        </div>

        <div class="dev-row">
            <div class="dev-key">login cookie</div>
            <div class="dev-equals"> = </div>
            <div class="dev-value">{{ StateUser.state.loginCookie }}</div>
        </div>
        
    </div>
</template>




<script>

export default {
    name: "DeckOverview"
};

</script>



<style scoped>

.dev-menu {
    background-color: rgb(12, 142, 142);

    overflow: hidden;
    
    position: absolute;
    z-index: 2000;

    border: 1px solid #ccc;

    top: 0px;
    left: 0px;
    height: 30px;
    width: 30px;
}

.dev-menu:hover {
    height: 500px;
    width: 300px;
}

.dev-row {
    display: flex;
    flex-direction: row;
    justify-content: start;

    padding: 10px;

    border-bottom: solid black 2px;
}

.dev-key {
    width: 100px;
    /* border-right: solid rgba(0, 0, 0, 0.505) 1px; */
}

.dev-equals {
    width: 20px;
}

</style>