<template>
  <form @submit.prevent="handleSubmit" class="new-user-form center">
    <div class="form-group">
      <label for="username">Username</label>
      <input
        id="username"
        v-model="formData.username"
        type="text"
        value="u"
        required
      />
    </div>

    <div class="form-group">
      <label for="password">Password</label>
      <input
        id="password"
        v-model="formData.password"
        type="password"
        value="p"
        required
      />
    </div>

    <div class="form-group">
      <label for="confirmPassword">Confirm Password</label>
      <input
        id="confirmPassword"
        v-model="formData.confirmPassword"
        type="password"
        value="p"
        required
      />
      <p v-if="passwordsDoNotMatch" class="error-message">
        Passwords do not match
      </p>
    </div>

    <button
      type="submit"
      :disabled="!isFormValid"
    >
      Create Account
    </button>
  </form>
</template>




<script>
// import { onUpdated } from 'vue';

import { baseApiUrl } from '@/modules/api';

import { autoFillInputs, testData } from '@/dev/CreateUser-dev';
autoFillInputs(testData.selectors, testData.values);



export default {
  data() {
    return {
      formData: {
        username: '',
        password: '',
        confirmPassword: ''
      }
    }
  },
  computed: {
    passwordsDoNotMatch() {
      return this.formData.password && 
             this.formData.confirmPassword && 
             this.formData.password !== this.formData.confirmPassword
    },
    isFormValid() {
      return this.formData.username && 
             this.formData.password && 
             this.formData.confirmPassword && 
             !this.passwordsDoNotMatch
    }
  },
  methods: {
    async handleSubmit() {
      if (this.isFormValid) {
        // Here you would typically make an API call to create the user
        // console.log('Form submitted:', {
        //   username: this.formData.username,
        //   password: this.formData.password
        // })

        this.loading = true
      this.error = null

      try {
        // Register the user
        const registerResponse = await fetch(baseApiUrl + '/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.formData.username,
            password: this.formData.password,
            isGuest: "0",
          })
        })

        if (!registerResponse.ok) {
            alert("Account Creation Failed" + "\nError: " + (await registerResponse.json()).Error)
            throw new Error('Registration failed. Please try again.')
        }


        // If registration successful, proceed with login
        const loginResponse = await fetch(baseApiUrl + '/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.formData.username,
            password: this.formData.password
          })
        })

        if (!loginResponse.ok) {
            alert("Login Failed")
          throw new Error('Login failed after registration. Please try logging in manually.')
        }

        // If both registration and login are successful, redirect to dashboard
        // window.location.href = '/dashboard'
        // alert("Login succefull!!")
        this.$router.push('/dashboard-user');

      } catch (err) {
        this.error = err.message
      } finally {
        this.loading = false
      }

      }
    }
  }
}
</script>

<style scoped>
.new-user-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;

  height: 100%;
  
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}
 
input {
  width: 100%;

  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 3px rgba(74, 144, 226, 0.3);
}

button {
  width: 100%;
  padding: 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #357abd;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}
</style>