

<script setup>

import { ref } from 'vue';
import { onMounted } from 'vue';

import { baseApiUrl, newRound } from '@/modules/api';

import DeckInfo from './DeckInfo.vue';
// import RoundInfo from './RoundInfo.vue';

import { StateUser } from '@/modules/StateImges';
// console.log(" Store.username =", State.username)
// State.username = "nomnom"; 
// console.log(" Store.username =", State.username)   

const decks = ref([]);
const round = ref([]);
 

// Fetch cards on mount
const fetchDecks = async () => {
    console.log("FETCH")
    try {
    const decksRequest = await fetch(baseApiUrl + '/decks');

    // GET USER UUID AND DECK UUID
    console.log("fetching round: ")
    console.log("useruuid: ", StateUser.getUseruuid())
    console.log("deckuuid: ", StateUser.userUuid)
    const roundQueryParams = ``;
    const roundRequest = await fetch(baseApiUrl + '/rounds?' + roundQueryParams);
    
    if (decksRequest.ok && roundRequest.ok) {
        decks.value = await decksRequest.json();
        round.value = await roundRequest.json();
    } else {
        console.error('Failed to fetch cards');
    }
    } catch (error) {
    console.error('Error:', error);
    }
};

// return { cards };

onMounted(() => {
    fetchDecks()
    console.log("Mounted Deck-List")
})


</script>




<template>
    <div class="DeckOverview">
        
        <h1>ROUND PLAY</h1>

        <DeckInfo v-for="deck in decks" :key="deck.uuid" :name="deck.name" :deckSize="deck.deckSize" />
    
    </div>
</template>



<script>


export default {
    name: "DeckOverview",
    methods: {
        async start_deck(){
            console.log("START DECK CLICKED");
            let _isLoggedIn = StateUser.isLoggedIn();
            if(!_isLoggedIn){
                console.error("UNABLE TO CREATE NEW ROUND");
                return;
            }

            console.log("IS LOGGED IN");

            console.log("window.location.pathname =", window.location.search)
            const urlParams = new URLSearchParams(window.location.search);
            console.log("urlParams.get(\"deckuuid\") =", urlParams.get("deckuuid"))
            const _deckuuid = urlParams.get("deckuuid");
            const _useruuid = StateUser.getUseruuid();

            // console.log(_deckuuid + _useruuid)
            
            let _newRound = await newRound(_deckuuid, _useruuid);
            console.log("newRound =", _newRound)
            
            console.log("NEW ROUND !  SENDING TO FIRST CARD!")
            
            
        }
    }
};

</script>





<style>
.DeckOverview {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}

#start-deck-btn {
    padding: 10px;
}
#start-deck-btn:hover {
    cursor: pointer;
}
</style>