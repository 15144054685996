
export class Cookies {

    static clear(){

        // https://www.tutorialspoint.com/how-to-clear-all-cookies-with-javascript
        let _cookies = document.cookie.split(';');
 // set past expiry to all cookies
for (var i = 0; i < _cookies.length; i++) {
   document.cookie = _cookies[i] + "=; Max-Age=0";
}


        // const cookies = document.cookie.split(';').reduce((acc, cookie) => {
        // const [name, value] = cookie.trim().split('=');
        // acc[name] = value;
        // return acc;
        // }, {});

        // remove specific cookoie
        // if(cookies[key]){
        //    cookies.
        // }
        // delete cookies[key];

        // let newCookieString = "";

        // for (let _key in cookies){
        //     console.log( _key + "=" + cookies[_key]);
        //     newCookieString = newCookieString + _key + "=" + cookies[_key]
        // }
        // document.cookie = newCookieString;
        // this.printCookies();
    }

    static printCookies(){
        // for (let _key in cookies){
        //     console.log( key + "=" + cookies[_key]);
        // }
        console.log("cookies: ", document.cookie)
    }

    static get(key){
        const cookies = document.cookie.split(';').reduce((acc, cookie) => {
        const [name, value] = cookie.trim().split('=');
        acc[name] = value;
        return acc;
        }, {});

        // console.log("key =", key)
        // console.log("cookies =", cookies)
        // console.log("cookies[key] =", cookies[key])

        if(!cookies[key]){
            console.error(`Cookie for key '${key}' was not found.`)
            return null;
        }

        return cookies[key];
    }
}





