<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    name: String,
    uuid: String,
})


</script>




<template>

    <div class="deck-list-item orange center">

        <router-link :to="{ path: '/deck-overview', query: { deckuuid: props.uuid } }">

            <div :data-uuid="props.uuid">{{ props.name }}</div>

        </router-link>
    
    </div>

</template>

<script>
export default {
    name: "DeckListItem"
};
</script>





<style>
.deck-list-item {
    /* border: 1px solid #ccc; */
    /* padding: 1rem; */
    /* margin-bottom: 1rem; */
    /* border-top: 10px; */
    text-decoration: none;
    color: black;

    width: 80%;
    /* padding: 30px; */
    border-radius: 10px;
}

.deck-list-item > a {
    padding: 30px;

    text-decoration: none;
    color: black;

    width: 100%;
    height: 100%;
}

.deck-list-item * {
    font-size: x-large;
    font-weight: 700;
}

</style>