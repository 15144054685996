<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    name: String,
    deckSize: Number,
})


</script>




<template>
    <div class="deck">
        
        <h1>DECK INFO</h1>

        <h3>Deck Name: {{ props.name }}</h3>
        <h3>Deck Size: {{ props.deckSize }}</h3>
    
    </div>
</template>

<script>
export default {
    name: "DeckInfo"
};
</script>





<style>
.deck {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}
</style>